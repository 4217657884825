<template>
<div card class="p-5 bg-white">
	<!-- Loading Alert container -->
	<div v-if="loading" class="loading-alert">
      <b-alert show variant="info" class="d-flex justify-content-center align-items-center">
        <span class="spinner-wrapper"><b-spinner small></b-spinner></span>
		Updating password...
      </b-alert>
    </div>
	<!-- Alert container -->
	<div v-if="results" class="results-alert">
      <b-alert show variant="success" class="d-flex justify-content-center align-items-center">
        {{ results }}
      </b-alert>
    </div>
	<div card-title>
		<h3 class="mb-10 font-weight-bold text-dark">
			Update password for: <span class="font-italic">{{ data.email }}</span>
		</h3>
	</div>
	<div card-body v-if="!results">
		<div class="d-flex flex-column align-items-center" style=" margin: 0 5%">
			<b-alert v-if="error" show variant="danger" class="mb-10 text-center font-weight-bold">
				{{errorMsg}} <br />Please choose a more secure password.
			</b-alert>
			<div class="form-group col-5">
				<label>New Password</label>
				<b-form-input type="password" v-model="password.newPW" ref="newPW" />
			</div>
			<div class="form-group col-5">
				<label>Verify Password</label>
				<b-form-input type="password" v-model="password.verifyPW" />
				<p v-show="!validateNewPW" class="text-danger">
					Passwords must be 8 or more characters.
				</p>
			</div>
		</div>
		<div class="d-flex justify-content-center border-top mt-5 pt-10">
			<div>
				<button type="button" class="btn btn-secondary font-weight-bolder text-uppercase px-9 py-4 mr-5" @click="cancelPW()">
					Cancel
				</button>
				<button :disabled="!validateNewPW" type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" @click="changePW()">
					Change Password
				</button>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
	props: ['data'],
	data() {
		return {
			loading: false,
			password: {
				newPW: null,
				verifyPW: null,
			},
			results: '',
			error: false,
			errorMsg: '',
		};
	},
	computed: {
		validateNewPW() {
			return this.password.newPW == this.password.verifyPW && this.password.newPW && this.password.newPW.length > 7;
		},
	},
	methods: {
		changePW() {
			this.loading = true;
			this.error = false;
			if (this.validateNewPW) {
				this.$http
					.post('changepw', { username: this.data.username, email: this.data.email, pass: this.password.newPW })
					.then(res => {
						if (res.data && res.data.data) {
							this.results = res.data.data;
							this.password.newPW = '';
							this.password.verifyPW = '';
						} else {
							this.results = res;
						}
						this.loading = false;
					})
					.catch(error => {
						this.loading = false;
						if (error.response.status == 400) {
							this.errorMsg = error.response.data.data;
							this.password.newPW = '';
							this.password.verifyPW = '';
							this.error = true;
							this.$refs.newPW.focus();
						}
					});
			}
		},
		cancelPW() {
			this.password = {
				newPW: null,
				verifyPW: null,
			};
		},
	},
};
</script>

<style scoped>

.results-alert {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  z-index: 1050; /* Adjust as needed to be above other elements */
  pointer-events: none; /* Allows clicking through the alert */
}

.results-alert .b-alert {
  pointer-events: auto; /* Enables interaction with the alert */
  width: 70%; /* Or a fixed width like 80% */
  max-width: 600px; /* Maximum width */
  padding: 1rem 2rem;
}

.loading-alert {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white background */
  z-index: 5; /* Adjust if needed to be above other elements */
}

.loading-alert .b-alert {
  display: inline-flex; /* Align spinner and text */
  align-items: center;
  padding: 1rem 2rem; /* Adjust the padding */
}

.spinner-wrapper {
  margin-right: 1rem; /* Add space between the spinner and the text */
}


</style>
